import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import sadPuppy from "./assests/sadpuppy.jpg";
import { sendErrorReport } from "./utils/sendError";

const NotFound = () => {
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname;

  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate("/");
  };
  // debugger;
  let info = { message: path };

  sendErrorReport(info, "path not found", userData);

  return (
    <>
      <div className="not-found">
        <h1 className="not-found-title">Oops. Page not found.</h1>
        <div className="not-found-message">
          <p>
            We are very sorry for the inconvenience. It looks like you're trying
            to access a page that was deleted or never even existed.
          </p>
        </div>
        <div className="not-found-actions">
          <button onClick={goBack} className="ripple-button">
            Go Back
          </button>
          <button onClick={goHome} className="ripple-button">
            Go Home
          </button>
        </div>
        <div style={{ marginTop: "50px" }}>
          <img src={sadPuppy} width="350px" />
        </div>
      </div>
    </>
  );
};

export default NotFound;
